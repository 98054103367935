import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=295dae84"
import script from "./Payment.vue?vue&type=script&lang=js"
export * from "./Payment.vue?vue&type=script&lang=js"
import style0 from "./Payment.vue?vue&type=style&index=0&id=295dae84&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QSkeleton,QBtn,QTooltip,QSeparator,QToggle,QIcon,QColor});
